<template>
  <div id="parkingDensity-passagewayStatisticsRanking" v-loading="loading">
    <ul
        v-for="(item, index) of list"
        :key="item.name"
    >
      <li
          class="ranking-img"
          v-if="index === 0 || index === 1 || index === 2"
      >
        <el-image
            :src="require(`@/assets/images/ranking${index + 1}.png`)"
            fit="contain"
        ></el-image>
      </li>
      <li v-else>{{ index + 1 }}</li>
      <li :title="item.name">{{ item.name }}</li>
      <li class="progress">
        <el-progress :percentage="item.percentage"></el-progress>
      </li>
      <li>{{ item.value }}</li>
    </ul>
  </div>
</template>

<script>

export default {
  props:{
    list:{
      type:Array,
      default:()=>[]
    }
  },
  data() {
    return{
      loading:false,
    }
  }
}
</script>

<style lang="scss">
#parkingDensity-passagewayStatisticsRanking {
  width: 100%;
  height: 100%;
  overflow-y: overlay;
  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }

    li {
      text-align: center;
      font-size: 1rem;
      @include overflow-ellipsis;

      &:first-child {
        flex: initial;
        display: inline-block;
        width: 1.45rem;
        height: 1.45rem;
        line-height: 1.45rem;
        border-radius: 50%;
        background-color: #02425e;
      }
      &:nth-child(2),
      &:nth-child(4){
        width: 25%;
      }
      &.ranking-img {
        background-color: initial;
      }

      .el-image {
        width: 1.45rem;
        height: 1.45rem;
      }

    }
    .progress {
      width:calc(50% - 1.45rem);
      .el-progress__text{
        font-size: 12px !important;
        color: white;
      }
    }
  }

}
</style>
